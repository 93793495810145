<template id="code">
<v-main style="padding-left: 100px; padding-right: 100px;">
	<side-bar-admin/>
		<!-- SideBar -->
		<!-- Barre de Navigation -->
		<div class="row-around" style="width: 100%;">
				<div class="div-creation-1">
					<h4 class="title-card">Création d'un Code d'Accès ou Création</h4>
					<v-row>
						<v-subheader>Nombre d'utilisation max</v-subheader>
						<v-col class="pr-4"> 
							<v-slider v-model="slider" class="align-center" :max="max" :min="min" hide-details>
								<template v-slot:append>
									<v-text-field v-model="slider" class="mt-0 pt-0"  hide-details single-line type="number" style="width: 60px"></v-text-field>
								</template>
							</v-slider>
						</v-col>
					</v-row>
					<br><br>
					<v-card-actions class="row-around">
						<button class="button-admin" @click.prevent="CreateCode()" >Générer</button>
						<h2>{{this.code}}</h2>
					</v-card-actions>
					<br><br>
					<v-row>
						<v-col cols="1"></v-col>
						<v-col cols="10">
							<label for="monInput">Informations complémentaires</label>
							<input class="text-input" type="text" id="monInput" name="sujet" v-model="sujetInput" required>
						</v-col>
					</v-row>
					<br><br>
					<div style="width: 100%;" class="row-around" >
						<button class="button-admin" @click.prevent="sendCode()"> Insérer le Code </button>
					</div>
				</div>
			<template  v-if="CodeDatabase">
			<div class="div-creation-1">
				<h4 class="title-card">Liste des Codes Actifs</h4>
				<v-data-table
				:headers="tableHeaders"
				:items="CodeDatabase"
				hide-default-footer
				class="tableau-code"
				>
					<template v-slot:item.token="{ item }">
						{{ item.token }}
					</template>
					<template v-slot:item.number_of_uses="{ item }">
						{{ item.number_of_uses }}
					</template>
					<template v-slot:item.max_of_uses="{ item }">
						{{ item.max_of_uses }}
					</template>
					<template v-slot:item.action="{ item }">
						<button class="button-suppress" @click="suppressCode(item.token)"><v-icon class="icon-button">mdi-close</v-icon></button>
					</template>
					
				</v-data-table>
			</div>
			</template>

		</div>
</v-main>
</template>


<script>
import SideBarAdmin from '../components/SideBarAdmin.vue';
	export default {
  components: { SideBarAdmin },
		
		data(){
			return {
				code : 0,
                min: 1,
                max: 10000,
                slider: 0,
				CodeDatabase : [],
				sujetInput: '',
				tableHeaders: [
					{
						text: 'Code',
						align: 'start',
						sortable: false,
						value: 'token',
					},
					{ text: 'nombre d\'utilisation', value: 'number_of_uses' },
					{ text: 'nombre d\'utilisation max', value: 'max_of_uses' },
					{ text: 'Informations', value: 'informations' },
					{ text: 'Suppression', value: 'action' },
				],
				codeToSuppress : '',
			}
		},
		async mounted(){	
			await this.getCodeFromDatabase();
		},
		methods: {
			//0- Génère un code aléatoire
			async CreateCode(){
                const min = Math.ceil(100000);
                const max = Math.floor(999999);
                this.code = Math.floor(Math.random() * (max - min)) + min;
                console.log(this.code)
            },
			//1- envoie du code selon 1 test
			async sendCode(){
				const codeTested = await Promise.all([this.testCode()])
				console.log(codeTested[0])
				if(codeTested[0]){
					const codeInserted = await Promise.all([this.PushCodeDatabase()])
					if(codeInserted){
						window.alert('code inséré dans la base de donnée')
					}else{
						window.alert('erreur lors de l\'envoie du code à la base de donnée')
					}
				}else{
					window.alert('veuillez reéssayer')
				}
			},
			//1.1- test le code généré, si il est bien compris entre 100000 et 999999
			//1.2- test le nombre d'utilisation max > 0
			async testCode(){
				if(100000 < this.code < 999999 && this.slider > 0 ){
					window.alert('code accepté')
					return true;
				}else{
					window.alert('code aléatoire erroné ou nombre d\'utilisation max == 0')
					return false;
				}
			},	
			//2- Insertion du code dans la bdd
			async PushCodeDatabase(){
				const url = this.$api.getRESTApiUri() + `/create-code`
				return fetch(url, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							max_of_uses: this.slider,
                            token: this.code,
                            number_of_uses: 0,
							informations : this.sujetInput,
						}),
						
				})
				.then(response => response.json())
				.then(json => console.log(json),
						console.log('ok'),
						this.CodeDatabase.push({
							max_of_uses: this.slider,
                            token: this.code,
                            number_of_uses: 0,
							informations : this.sujetInput,
						})
				);		
			},
			async getCodeFromDatabase(){
				console.log('hehee')
				const url = this.$api.getRESTApiUri() + `/get-code`;
				fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
				})
				.then(res => res.text())
				.then((result) => {
					this.CodeDatabase = JSON.parse(result)
					console.log(JSON.parse(result))
					console.log(this.CodeDatabase)
				})
				.catch((error) => {
					console.log(error)
				}); 
			},
			async suppressCode(codeToSuppress){
				console.log(codeToSuppress)
				const url = this.$api.getRESTApiUri() + `/suppress-code/${codeToSuppress}`;
				try {
					const response = await fetch(url, {
						method: 'PUT',
					});
					if (response.ok) {
						console.log('Code deleted successfully');

						// Après la suppression réussie, filtrez l'utilisateur supprimé du tableau users.
						this.CodeDatabase = this.CodeDatabase.filter((code) => code.token !== codeToSuppress);
						// Fermez la boîte de dialogue.
					} else {
						console.error('Error:', response.status);
						// Gérez l'erreur si nécessaire
					}
				} catch (error) {
					console.error('Error:', error);
					// Gérez l'erreur si nécessaire
				}
			}
		}
	}
</script>

<style scoped>

.div-creation-1{
	width: 45%;
	margin: 150px 0px;
	background-color: white;
	border-radius: 20px;
	padding: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.title-card{
	font-family: Roboto;
    font-size: 1.5rem;
    color: var(--vertfonce);
	margin-bottom: 20px;
	text-align: center;
}
.tableau-code{
	height: 40vh;
	max-height: calc(95vh - 200px);
    overflow-y: auto;
}

.button-admin{
	background-color: var(--vertfonce);
	color: white;
	border-radius: 15px;
	padding: 8px 15px;
	font-family: 'Roboto';
	border: solid 2px;
}
.button-admin:hover{
	background-color: transparent;
	color: var(--vertfonce);
	transition: 0.5s;
}

.button-suppress{
	justify-content: center;
	background-color: firebrick;
	border: solid 1px firebrick;
	padding: 4px 8px;
	border-radius: 5px;
	
}
.button-suppress:hover{
	background-color: white;
	border: solid 1px firebrick;
}

.icon-button{
	color: white;
}
.icon-button:hover{
	color: firebrick;
}
.text-input{
	width: 100%;
	border: 2px solid var(--vertfonce);
	border-radius: 5px;
	background-color: white;
	height: 55px;
	padding: 10px;
	margin-bottom: 15px;
}
</style>